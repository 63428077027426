import React, { useState, useRef } from "react";
import { MailIcon, PhoneIcon, AtSymbolIcon } from "@heroicons/react/outline";

const Form = () => {
  const formRef = useRef(null);
  const scriptUrl =
    "https://script.google.com/macros/s/AKfycbwa7TCAPQGKjEA9o1I4B0zh9bpsr_YcXqgOE-oBqia2Gv4HkuVWhQZHLGS86wDjej4K/exec";
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(scriptUrl, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then((res) => {
        //console.log("SUCCESSFULLY SUBMITTED")
        window.location.assign("/contact-validation/");
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-4 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12 hidden lg:block">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Compétences et proximité
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Expertises indépendante des fissures, études de sol G5,
              consolidation des maisons et réparation des désordres...
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Nos équipes internes et partenaires interviennent dans différents
              types de contexte fissuration :<br />
              Avis avant achat immobilier, garantie dommage-ouvrage et
              décennale, garantie des catastrophes naturelles sécheresse, litige
              amiable ou judiciaire
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Intervention partout en France
              <br />
              Obtenez l’aide dans les plus brefs délais en remplissant le
              formulaire.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <p className="mt-3 text-lg leading-6 text-gray-500">
                  En cas d'urgence, nous contacter au :
                </p>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">06 63 86 19 84</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">
                    contact
                    <AtSymbolIcon className="h-5 inline" />
                    koudepouce.fr
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form className="gap-y-6" ref={formRef} onSubmit={handleSubmit}>
              <div className="pb-4">
                <label htmlFor="nom" className="sr-only">
                  Nom
                </label>
                <input
                  type="text"
                  name="Nom"
                  id="nom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Nom"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="Prénom" className="sr-only">
                  Prénom
                </label>
                <input
                  type="text"
                  name="Prénom"
                  id="prenom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Prénom"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="cp" className="sr-only">
                  Code postal
                </label>
                <input
                  type="number"
                  name="Code Postal"
                  id="cp"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Code postal"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="Email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="telephone" className="sr-only">
                  Téléphone
                </label>
                <input
                  type="number"
                  name="Téléphone"
                  id="telephone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Téléphone"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="phone" className="sr-only">
                  Type de demande
                </label>
                <select
                  name="Catégorie"
                  id="specialite"
                  autoComplete="specialite"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
                  placeholder="Votre demande porte sur"
                  required
                >
                  <option value="">---</option>
                  <option value="Kit fissuration">Kit fissuration</option>
                  <option value="Expertise bâtiment">Expertise bâtiment</option>
                  <option value="Avocat">Avocat</option>
                  <option value="Etude de sol G5">Etude de sol G5</option>
                  <option value="Assistance à Maitrise d’ouvrage">
                    Assistance à Maitrise d’ouvrage
                  </option>
                  <option value="Maitrise d’oeuvre">Maitrise d’oeuvre</option>
                  <option value="Travaux d’injection résine expansive">
                    Travaux d’injection résine expansive
                  </option>
                  <option value="Travaux de micropieux">
                    Travaux de micropieux
                  </option>
                  <option value="Je ne sais pas">Je ne sais pas</option>
                </select>
              </div>
              <div className="pb-4">
                <label htmlFor="message" className="sr-only">
                  Votre besoin
                </label>
                <textarea
                  id="message"
                  name="Message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border border-gray-300 rounded-md"
                  placeholder="Votre besoin"
                  defaultValue={""}
                  required
                />
              </div>
              <div className="pb-4">
                <input
                  type="checkbox"
                  id="mise_en_relation"
                  name="Mise en relation"
                  className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  required
                />
                J’accepte d’entrer en relation avec Koudepouce et ses
                partenaires
              </div>
              <div className="pb-4">
                <input
                  type="checkbox"
                  id="cgu"
                  name="CGU"
                  className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  required
                />
                J'accepte les{" "}
                <a
                  href="/legal/cgu/"
                  className="text-orange-500"
                  target="_blank"
                  rel="noreferrer"
                >
                  Conditions Générales d'Utilisation
                </a>
              </div>

              <div className="hidden">
                <label htmlFor="tellepro" className="sr-only">
                  Téllépro
                </label>
                <input
                  type="text"
                  name="tellepro"
                  id="tellepro"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Téllépro"
                />
                <input
                  type="text"
                  name="Source"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Source"
                  value="www.koudepouce.fr"
                />
                <input
                  type="text"
                  name="Moyen"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Moyen"
                  value="Formulaire"
                />

              </div>

              <div className="pb-4">
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  {loading ? "Envoi en cours ..." : "Valider"}
                </button>
              </div>
            </form>
            <p className="mt-3 text-xs leading-6 text-gray-400">
              Les informations recueillies sur ce formulaire sont enregistrées
              dans un fichier informatisé par Koudepouce SAS visant à traiter
              votre demande. Elles sont conservées pendant au moins 3 ans et
              sont destinées au service marketing et au service commercial de
              Koudepouce. Nos mentions légales sont accessibles ici.
              Conformément à la loi « informatique et libertés », vous pouvez
              exercer votre droit d’accès aux données vous concernant et les
              faire rectifier en contactant : Koudepouce, Service de la
              protection des données, 97 Allée Alexandre Borodine, 69800
              Saint-Priest. ou par e-mail sur contact
              <AtSymbolIcon className="h-5 inline" />
              koudepouce.fr en précisant dans l’objet du courrier « Droit des
              personnes » et en joignant la copie de votre justificatif
              d’identité.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
